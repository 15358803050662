.outro {
  margin-bottom: 10%;
  margin-left: 3%;
  margin-right: 3%;
  margin-top: 30px;
  text-align: left;
  border: 2px solid black;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 5px 10px #888888;
}

.outro h4 {
  padding-left: 3%;
  padding-right: 3%;
  margin-bottom: 5px;
  margin-top: 30px;
  font-family: 'Droid Sans', Helvetica, Arial, sans-serif;
  font-size: 16px;
  color: #5d5d5d;
  text-shadow: 0 1px 0 rgba(255,255,255,.7);
}

.outro p {
  padding-left: 3%;
  padding-right: 3%;
  padding-bottom: 15px;
  line-height: 20px;
  font-size: 15px;
  text-align: justify;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  color: #888;
}

.button-to-start {
  text-align: center;
  margin-bottom: 15px;
  margin-top: 5px;
}

button {
  border-radius: 4px;
  background-color: #969696;
  border: none;
  color: #FFFFFF;
  text-align: center;
  font-size: 20px;
  padding: 10px;
  width: 160px;
  transition: all 0.5s;
  cursor: pointer;
}

button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

button:hover span {
  padding-right: 25px;
}

button:hover span:after {
  opacity: 1;
  right: 0;
}
