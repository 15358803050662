@font-face {
  font-family: "Comfortaa";
  src:
    url("../assets/fonts/Comfortaa-Bold.ttf") format('truetype');
  font-style: normal;
  font-weight: 700;
}

header {
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

header .logo {
  text-align: center;
  color: #337ab7;
  font-family: 'Comfortaa';
  font-size: 3em;
  font-weight: 700;
  margin-top: 6px;
  margin-bottom: 0;
}

header .site-title {
  color: #3d454a;
  text-align: center;
  text-decoration: none;
  font-size: 22px;
  margin-top: 0;
  margin-bottom: 1%;
}

header .logo a:link {
  text-decoration: none;
  outline: none;
  color: #337ab7;
}

header .logo a:visited {
  text-decoration: none;
  outline: none;
  color: #337ab7;
}

header .logo a:active {
  text-decoration: none;
  outline: none;
  color: #337ab7;
}

header .logo a:hover {
  text-decoration: none;
  outline: none;
  opacity: 0.7;
} 
