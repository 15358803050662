.loading-display {
    height: 100vh;
    width: 100%;

    display: flex;
    flex-direction: column;

    background-color: #f1f1f1;

    text-align: center;
    justify-content: center;
    align-items: center;
}

.loading-display .spinner {
    box-sizing: border-box;
}

.loading-display .description {
    box-sizing: border-box;
    padding: 0 2%;
}

.loading-display .description p {
    color: #676767;

    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1.8em;
}